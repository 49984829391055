jQuery('a[data-scroller][href^="#"]').on('click', function (event) {
    var href = this.href.split('#');
    var target = jQuery('#' + href[1]);

    if (target.length) {
        event.preventDefault();
        jQuery('html, body').animate({
            scrollTop: target.offset().top - jQuery( ".navbar.navbar-light" ).height()
        }, 300);
    }
});

// Collapse the navbar when page is scrolled
jQuery(window).scroll(function() {
    if (jQuery("#mainNav").offset().top > 100) {
        jQuery("#mainNav").addClass("navbar-shrink").removeClass("navbar-hidden");
    } else {
        jQuery("#mainNav").removeClass("navbar-shrink").addClass("navbar-hidden");
    }
});

jQuery( document ).ready(function() {
    jQuery('.circle-badge').tooltip();
    jQuery('.circle-badge').on('click', function(){
        jQuery('.circle-badge.active').tooltip('show');
    });

    jQuery('.circle-badge.active').tooltip('show');

    jQuery('#carouselExampleIndicators').on('slid.bs.carousel', function () {
        if(jQuery('.circle-badge').hasClass('active')) {
            jQuery('.circle-badge').tooltip('hide');
            jQuery('.circle-badge.active').tooltip('show');
        }
        else {
            jQuery('.circle-badge').tooltip('hide');
        }
    });

});
